import React, { useState } from "react"
import { SetStateAction, Dispatch } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/angebot/sonnenbrillen/header.jpg"
import BildObenLinks from "../../images/angebot/sonnenbrillen/serengeti-eyewear.jpg"
import BildObenRechts from "../../images/angebot/sonnenbrillen/scotchsoda.jpg"
import BildUnten from "../../images/angebot/sonnenbrillen/cebe.png"
import SubNavigation from "../../components/SubNavAngebot"
import Termin from "../../components/Termin"

interface SonnenbrillenProps {
  isSubMenuOpen: boolean
  setSubMenuOpen: Dispatch<SetStateAction<boolean>>
}

const Sonnenbrillen: React.FC<SonnenbrillenProps> = () => {
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)
  const siteTitle = "Sonenbrillen - Angebot"
  const greenVer = true

  return (
    <Layout title={siteTitle} greenVer={greenVer} background="#00aa9b">
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Für Heime und Spitäler"
      />
      <section className="wrapper greyColor content">
        <h1>Sonnenbrillen</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <div className="imageColumn">
                  <a href="https://www.serengeti-eyewear.com/" target="_blank">
                    <img src={BildObenLinks} />
                    <p className="imageCaption">
                      Serengeti – Top Kontrast bei jedem Sonnenlicht
                    </p>
                  </a>
                </div>
                <div className="imageColumn">
                  <a href="https://spectr-magazine.com/" target="_blank">
                    <img src={BildObenRechts} />
                    <p className="imageCaption">
                      Scotch & Soda – neu aus Holland eingetroffen
                    </p>
                  </a>
                </div>
              </div>
              <div className="imageRow">
                <div className="imageColumn fullwidth">
                  <a href="https://www.cebe.com/" target="_blank">
                    <img src={BildUnten} />
                    <p className="imageCaption">
                      Cébé - Sport und Kinderbrillen für Anspruchsvolle
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="contentColumn">
            <h2>Accessoire und Sehhilfe</h2>
            <p>
              Sonnenbrillen als modisches Lifestyle-Accessoire oder als modische
              Sehhilfe mit speziellem UV-Schutz. Bei SCHWARZ Optik zählt nicht
              nur die Wahl des optimalen Brillenglases zum Service sondern auch
              die Stylingberatung.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sonnenbrillen
